import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

function Footer() {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <Fragment>
      <section
        className={
          location.pathname === "/login" || location.pathname === "/register"
            ? "uk-hidden"
            : "as-footer-section"
        }
      >
        <div className="uk-flex uk-flex-center uk-text-center uk-visible@m as-app">
          <div className="uk-card uk-card-default uk-card-body uk-width-1-2 uk-border-rounded as-border-red-top">
            <h5 className="uk-h5">{t("FOOTER_GET_STARTED")}</h5>
            <p className="as-card">{t("FOOTER_GET_STARTED_TEXT")}</p>
            <div className="uk-flex uk-flex-center">
              <div className="">
                <a href="">
                  <img
                    src={require("../assets/img/googlestore.png").default}
                    alt=""
                    width="102.5"
                    height="31"
                  />
                </a>
              </div>
              <div className="uk-margin-left">
                <a href="">
                  <img
                    src={require("../assets/img/appstore.png").default}
                    alt=""
                    width="102.5"
                    height="31"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <footer className="as-footer">
          <div className="uk-container ">
            <div className="as-container">
              <ul className="uk-navbar-nav uk-flex uk-flex-center@m uk-flex uk-flex-left@s">
                <li className="uk-active ">
                  <a href="#" className="">
                    <img
                      src={require("../assets/img/logoRed.png").default}
                      className="as-logo-img"
                      alt=""
                    />{" "}
                    <span className="as-logo as-red-1 as-quantity-font">
                      WadPack
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="uk-child-width-1-5@m uk-grid as-container">
              <div>
                <h4 className="color-is-white uk-h6 uk-margin-top">
                  {t("FOOTER_MENU")}
                </h4>

                <ul className="uk-list color-is-gray-footer">
                  <li>
                    <Link to="/how" className="uk-link-text">
                      {t("FOOTER_CMT")}
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/particuliers" className="uk-link-text">
                      {t("FOOTER_ADVANTAGE")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/pricelist" className="uk-link-text">
                      {t("FOOTER_TARIF")}
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/about" className="uk-link-text">
                      {t("FOOTER_FAQ")}
                    </Link>
                  </li>

                  <li>
                    {" "}
                    <Link to="/contact" className="uk-link-text">
                      {t("FOOTER_CONTACT")}
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/covid" className="uk-link-text">
                      {t("FOOTER_COVID")}
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h4 className="color-is-white uk-h6 uk-margin-top">
                  {t("FOOTER_ACTIVITE")}
                </h4>

                <ul className="uk-list color-is-gray-footer">
                  <li>
                    <Link to="/station" className="uk-link-text">
                      {t("FOOTER_COLLECTIVE")}
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h4 className="color-is-white uk-h6 uk-margin-top">
                  {t("FOOTER_PRO")}
                </h4>

                <ul className="uk-list color-is-gray-footer">
                  <li>
                    <Link to="/partner" className="uk-link-text">
                      {t("FOOTER_DEV")}
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h4 className="color-is-white uk-h6 uk-margin-top">
                  {t("FOOTER_APPS")}
                </h4>

                <ul className="uk-list color-is-gray-footer">
                  <li>
                    <Link to="/" className="uk-link-text">
                      {t("FOOTER_IOS")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/" className="uk-link-text">
                      {t("FOOTER_ANDROID")}
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h4 className="color-is-white uk-h6 uk-margin-top">
                  {t("FOOTER_A_PROPOS")}
                </h4>

                <ul className="uk-list color-is-gray-footer">
                  <li>
                    <Link to="/about" className="uk-link-text">
                      {t("FOOTER_QUI_SOMMES")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/career" className="uk-link-text">
                      {t("FOOTER_CARR")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/" className="uk-link-text">
                      {t("FOOTER_PRESSE")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/" className="uk-link-text">
                      {t("FOOTER_PAYS")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <br />

            <div
              className="uk-grid-column-small uk-grid-row-large uk-child-width-1-2@s as-container"
              data-uk-grid
            >
              <Link to="/privacy" className="uk-link-text">
                <div className="uk-flex uk-flex-left@m">{t("FOOTER_COPY")}</div>
              </Link>
              <div>
                <div className="uk-flex uk-flex-right@m">
                  <a href="" className="uk-margin-small-right">
                    <img
                      src={require("../assets/img/twitter-1.png").default}
                      alt=""
                    />
                  </a>
                  <a href="" className="uk-margin-small-right">
                    <img
                      src={
                        require("../assets/img/facebook-logo-button.png")
                          .default
                      }
                      alt=""
                    />
                  </a>
                  <a href="" className="uk-margin-small-right">
                    <img
                      src={require("../assets/img/youtube.png").default}
                      alt=""
                    />
                  </a>
                  <a href="">
                    <img
                      src={require("../assets/img/google.png").default}
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </section>

      <div
        className={
          location.pathname === "/login" || location.pathname === "/register"
            ? "uk-hidden"
            : "as-footer-svg"
        }
      >
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          className="as-svg-foot"
        >
          <path
            d="M-0.85,33.83 C71.38,-38.20 375.56,39.77 500.84,58.52 L500.00,150.00 L0.00,150.00 Z"
            style={{ stroke: "none", fill: "#101010" }}
          ></path>
        </svg>
      </div>
    </Fragment>
  );
}

export default Footer;
