import React, { Fragment, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";

function PriceList() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Fragment>
      <section>
        <div className="uk-grid-small uk-child-width-expand@s" data-uk-grid>
          <div className="uk-padding uk-container uk-margin-xlarge-left">
            <h2 className="uk-text-bold"> Various Price List </h2>
            <div className="">
              <div className="">
                <small>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                  diam nonummy <br /> nibh euismod tincidunt ut laoreet dolore
                  magna aliquam erat volutpat. <br /> Ut wisi enim ad minim
                  veniam, quis nostrud{" "}
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="uk-padding-large as-price-section">
          <div
            className="uk-grid-small uk-child-width-expand@m uk-text-center uk-margin-top"
            data-uk-grid
            data-uk-height-match="target: > div > .uk-card"
          >
            <div className="uk-visible@m">
              <div className="uk-padding-remove-horizontal  as-label-left">
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <p className="as-font-13 ">0 to 1 heure</p>
                <p className="as-font-13">24 heure</p>
                <p className="as-font-13">Une semaine</p>
                <p className="as-font-13">Un mois</p>
                <p className="as-font-13">12 mois</p>
              </div>
            </div>
            <div>
              <div className="uk-card as-bg-grey3 uk-padding-small uk-card-body">
                <img
                  src={require("../../../assets/img/syation.png").default}
                  alt=""
                  width="74"
                  height="74"
                />{" "}
                <br />
                <small className="as-red">Station Service</small>
                <hr />
                <p className="uk-text-bold as-margin-top-span">
                  <span className="uk-hidden@m">0 to 1 heure: </span> 150
                </p>
                <p className="uk-text-bold">
                  <span className="uk-hidden@m">24 heure: </span> 3600
                </p>
                <p className="uk-text-bold">
                  <span className="uk-hidden@m">Une semaine: </span> 25,200
                </p>
                <p className="uk-text-bold">
                  <span className="uk-hidden@m">Un mois: </span> 108,000
                </p>
                <p className="uk-text-bold">
                  <span className="uk-hidden@m">12 mois: </span> 1,296,000
                </p>
                <hr />
                <small>
                  Une commission de 30% est prélevée par WadPark sur toutes les
                  transactions.abonnement n'est valable que pour une station
                  service.
                </small>
              </div>
            </div>
            <div>
              <div className="uk-card as-bg-grey3 uk-padding-small uk-card-body">
                <img
                  src={require("../../../assets/img/town.png").default}
                  alt=""
                />{" "}
                <br />
                <small className="as-red">Hôtel de ville</small>
                <hr />
                <p className="uk-text-bold as-margin-top-span">
                  <span className="uk-hidden@m">0 to 1 heure: </span> 150
                </p>
                <p className="uk-text-bold">
                  <span className="uk-hidden@m">24 heures: </span> 3600
                </p>
                <p className="uk-text-bold">
                  <span className="uk-hidden@m">Une semaine: </span> 25,200
                </p>
                <p className="uk-text-bold">
                  <span className="uk-hidden@m">Un mois: </span> 108,000
                </p>
                <p className="uk-text-bold">
                  <span className="uk-hidden@m">12 mois: </span> 1,296,000
                </p>
                <hr />
                <small>
                  Une commission de 30% est prélevée par WadPark sur toutes les
                  transactions.abonnement n'est valable que pour une mairie.
                </small>
              </div>
            </div>
            <div>
              <div className="uk-card as-bg-grey3 uk-padding-small uk-card-body">
                <img
                  src={require("../../../assets/img/hos.png").default}
                  alt=""
                />{" "}
                <br />
                <small className="as-red">Centres hospitaliers</small>
                <hr />
                <p className="uk-text-bold as-margin-top-span">
                  <span className="uk-hidden@m">0 to 1 heure: </span> 150
                </p>
                <p className="uk-text-bold">
                  <span className="uk-hidden@m">24 heure: </span> 3600
                </p>
                <p className="uk-text-bold">
                  <span className="uk-hidden@m">Une semaine: </span> 25,200
                </p>
                <p className="uk-text-bold">
                  <span className="uk-hidden@m">Un mois: </span>1 08,000
                </p>
                <p className="uk-text-bold">
                  <span className="uk-hidden@m">12 mois: </span> 1,296,000
                </p>
                <hr />
                <small>
                  Une commission de 30% est prélevée par WadPark sur toutes les
                  transactions.abonnement n'est valable que pour les centres
                  hospitaliers
                </small>
              </div>
            </div>
            <div>
              <div className="uk-card as-bg-grey3 uk-padding-small uk-card-body">
                <img
                  src={require("../../../assets/img/hotel.png").default}
                  alt=""
                />
                <br />
                <small className="as-red">Hôtels</small>
                <hr />
                <div
                  className="uk-child-width-expand@s uk-text-center"
                  data-uk-grid
                >
                  <div className="">
                    <small className="as-font-10">Extérieur</small>
                    <p className="uk-text-bold">
                      <span className="uk-hidden@m">0 to 1 heure: </span> 700
                    </p>
                    <p className="uk-text-bold">
                      <span className="uk-hidden@m">24 heure: </span> 10,000
                    </p>
                    <p className="uk-text-bold">
                      <span className="uk-hidden@m">Une semaine: </span> 22,500
                    </p>
                    <p className="uk-text-bold">
                      <span className="uk-hidden@m">Un mois: </span> 55,000
                    </p>
                    <p className="uk-text-bold">
                      <span className="uk-hidden@m">12 mois: </span>550,000
                    </p>
                  </div>

                  <div className="">
                    <small className="as-font-10">Sous-sol</small>
                    <p className="uk-text-bold">900</p>
                    <p className="uk-text-bold">12,000</p>
                    <p className="uk-text-bold">25,000</p>
                    <p className="uk-text-bold">58,000</p>
                    <p className="uk-text-bold">560,000</p>
                  </div>
                </div>
                <hr />
                <small>
                  Une commission de 30% est prélevée par WadPark sur toutes les
                  transactions. abonnement est valable pour le même groupe
                  hôtelier dans tout le Cameroun
                </small>
              </div>
            </div>

            <div>
              <div className="uk-card as-bg-grey3 uk-padding-small uk-card-body">
                <img
                  src={require("../../../assets/img/syation.png").default}
                  alt=""
                />
                <br />
                <small className="as-red">Stade</small>
                <hr />
                <div
                  className="uk-child-width-expand@s uk-text-center"
                  data-uk-grid
                >
                  <div className="">
                    <small className="as-font-10">Extérieur</small>
                    <p className="uk-text-bold">
                      <span className="uk-hidden@m">0 to 1 heure: </span> 700
                    </p>
                    <p className="uk-text-bold">
                      <span className="uk-hidden@m">24 heure: </span> 10,000
                    </p>
                    <p className="uk-text-bold">
                      <span className="uk-hidden@m">Une semaine: </span> 22,500
                    </p>
                    <p className="uk-text-bold">
                      <span className="uk-hidden@m">Un mois: </span> 55,000
                    </p>
                    <p className="uk-text-bold">
                      <span className="uk-hidden@m">12 mois: </span>550,000
                    </p>
                  </div>

                  <div className="">
                    <small className="as-font-10">Sous-sol</small>
                    <p className="uk-text-bold">900</p>
                    <p className="uk-text-bold">12,000</p>
                    <p className="uk-text-bold">25,000</p>
                    <p className="uk-text-bold">58,000</p>
                    <p className="uk-text-bold">560,000</p>
                  </div>
                </div>
                <hr />
                <small>
                  Une commission de 30% est prélevée par WadPark sur toutes les
                  transactions.abonnement n'est valable que pour un stade.
                </small>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}
export default PriceList;
