import React, { Fragment, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";

function Covid() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Fragment>
      <section className="as-woman uk-text-center uk-margin-large-top">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="uk-container">
          <div
            className="uk-grid-small uk-child-width-expand@s uk-grid"
            data-uk-grid
          >
            <div>
              <div className="uk-text-left uk-padding uk-margin-top ">
                <h2 className="uk-h2 uk-text-bold uk-text-left"> COVID-19</h2>
                <p className="uk-text-left">
                  Alors que le monde continue de réagir à COVID-19, nous nous
                  efforçons de faire notre part en assurant la sécurité de nos
                  employés, en nous efforçant de protéger la santé et le
                  bien-être des communautés dans lesquelles nous opérons et en
                  fournissant des technologies et des ressources à nos clients
                  pour les aider à faire de leur mieux à distance. L'évolution
                  de la pandémie de COVID-19 a un impact profond sur nos vies et
                  met à rude épreuve les systèmes de santé et la société dans
                  son ensemble. Face à cela nous avons pris plusieurs mesures
                  pour empêcher la propagation de ce virus dans notre
                  environnement
                </p>
              </div>
            </div>
            <div>
              <div className="uk-flex uk-flex-center@s uk-flex-right@m">
                <img
                  src={require("../../../assets/img/blackWoman.png").default}
                  className="test"
                  alt=""
                  width="250px"
                  height="450px"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section uk-container">
        <h5 className="uk-text-bold">
          Voici les principales mesures mises en œuvre par WardPark pour une
          riposte appropriée à la pandémie du Coronavirus (COVID-19)
        </h5>
        <div>
          <ul className="uk-list uk-list-square">
            <li>
              Soumission de l’ensemble du personnel à des tests de dépistage
              périodiques
            </li>
            <li>
              Mise en place d’un système de rotation des équipes et du
              télétravail
            </li>
            <li>
              Equipement de l’ensemble du personnel des masques de protection et
              visières de protection faciale
            </li>
            <li>
              Equipement de la structure des dispositifs de lavage des mains
            </li>
            <li>
              Port obligatoire du masque de protection obligatoire pour
              l’ensemble du personnel travaillant en présentiel
            </li>
            <li>
              Prise systématique de température à l’aide d’un thermoflash et
              lavage des mains à l’aide de solutions hydroalcooliques
              préalablement à l’accès à l’entreprise
            </li>
            <li>
              Restriction des contacts rapprochés comme faire la bise ou serrer
              la main
            </li>
            <li>
              Ouverture des réunions internes à un nombre maximal de 10
              participants, avec respect d’une distance minimale d’un mètre
              entre 2 participants
            </li>
            <li>
              Limitation des réunions impliquant des personnes externes à
              l’entreprise
            </li>
            <li>Interdiction des visites de tiers dans nos bureaux</li>
            <li>
              Prise de contact systématique avec le médecin du travail pour
              conduite à tenir, en cas de présentation de symptômes assimilables
              à ceux de la Covid-19.
            </li>
          </ul>
        </div>
      </section>
    </Fragment>
  );
}
export default Covid;
