import React, { Fragment, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";

function About() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <Fragment>
      <section>
        <div className="uk-grid-small uk-child-width-expand@s" data-uk-grid>
          <div className="uk-padding uk-container uk-margin-xlarge-left uk-margin-xlarge-top">
            <p className="as-red"> {t("ABOUT_TITLE")} </p>
            <h2 className="uk-text-bold uk-margin-remove-top">
              <Trans i18nKey="ABOUT_TITLE_TEXT"></Trans>
            </h2>
            <div className="">
              <div className="">
                <small>
                  <Trans i18nKey="ABOUT_TITLE_TEXT_SUB"></Trans>
                </small>
              </div>
            </div>
          </div>
          <div className="uk-visible@s">
            <img
              className="uk-margin-xlarge-top"
              src={require("../../../assets/img/gbd.png").default}
              alt=""
              uk-contain
              width="70%"
            />
          </div>
        </div>
      </section>

      <section className="uk-section uk-container">
        <div
          className="uk-child-width-1-2@m uk-child-width-1-1@s uk-grid-small uk-grid-match uk-flex uk-flex-middle"
          data-uk-grid
        >
          <div>
            <div className="uk-card uk-card-body">
              <img
                src={require("../../../assets/img/chise.png").default}
                alt=""
              />
            </div>
          </div>
          <div>
            <div className="uk-card uk-card-body">
              <h3 className="uk-card-title uk-text-bold">
                <Trans i18nKey="ABOUT_INNOVATION_TITLE" />
              </h3>
              <p>
                <Trans i18nKey="ABOUT_INNOVATION_TEXT" />
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section as-bg-grey3">
        <div className="uk-container">
          <div
            className="uk-child-width-1-2@m uk-child-width-1-1@s uk-grid-small uk-grid-match"
            data-uk-grid
          >
            <div>
              <div className="uk-card uk-card-body">
                <div className="uk-card uk-card-body">
                  <p className="as-red uk-margin-bottom">
                    <Trans i18nKey="ABOUT_VALUES" />
                  </p>
                  <h3 className="uk-card-title uk-text-bold uk-margin-remove-top">
                    <Trans i18nKey="ABOUT_VALUES_TEXT" />
                  </h3>
                </div>
                <div className="uk-card uk-card-body">
                  <p className="as-red uk-margin-bottom">
                    <Trans i18nKey="ABOUT_SIGNATURE" />
                  </p>
                  <h3 className="uk-card-title uk-text-bold uk-margin-remove-top">
                    <Trans i18nKey="ABOUT_SIGNATURE_TEXT" />
                  </h3>
                </div>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-body as-border-grey-left">
                <div className="uk-card uk-card-body">
                  <h4 className="uk-margin-bottom uk-text-bold">
                    <Trans i18nKey="ABOUT_MISSION" />
                  </h4>
                  <p className="uk-margin-remove-top">
                    <Trans i18nKey="ABOUT_MISSION_TEXT" />
                  </p>
                </div>
                <div className="uk-card uk-card-body">
                  <h4 className="uk-margin-bottom uk-text-bold">
                    <Trans i18nKey="ABOUT_VISION" />
                  </h4>
                  <p className="uk-margin-remove-top">
                    {" "}
                    <Trans i18nKey="ABOUT_VISION_TEXT" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section">
        <div className="uk-container uk-text-center">
          <h3 className="uk-text-bold">
            <Trans i18nKey="ABOUT_AMBITION" />
          </h3>
          <p className="">
            <Trans i18nKey="ABOUT_AMBITION_TEXT1" />
          </p>
          <p>
            <Trans i18nKey="ABOUT_AMBITION_TEXT2" />
          </p>
        </div>
      </section>
    </Fragment>
  );
}
export default About;
