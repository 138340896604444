import React, { Fragment, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";

function Home() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Fragment>
      <div
        className={"as-container uk-grid-small uk-flex uk-flex-middle as-top"}
        data-uk-grid
      >
        <div className="uk-width-1-2@s">
          <img
            src={require("../../../assets/img/phones.png").default}
            alt=""
            className="as-phones"
          />
        </div>
        <div className="uk-width-expand@s">
          <div className="">
            <section className="as-text-container">
              <div className="">
                <h1 className="as-white uk-text-left uk-h2 as-quantity-font">
                  {t("HOME_FIND")}
                </h1>
                <p className="as-white">{t("HOME_FIND_DESC")}</p>
              </div>
            </section>
            <section className="">
              <div className="uk-flex uk-flex-left uk-visible@s">
                <div className="">
                  <a href="">
                    <img
                      src={
                        require("../../../assets/img/googlestore.png").default
                      }
                      alt=""
                      width="102.5"
                      height="31"
                    />
                  </a>
                </div>
                <div className="uk-margin-left">
                  <a href="">
                    <img
                      src={require("../../../assets/img/appstore.png").default}
                      alt=""
                      width="102.5"
                      height="31"
                    />
                  </a>
                </div>
              </div>
              <div className="uk-flex uk-flex-center uk-hidden@s">
                <div className="">
                  <a href="">
                    <img
                      src={
                        require("../../../assets/img/googlestore.png").default
                      }
                      alt=""
                      width="102.5"
                      height="31"
                    />
                  </a>
                </div>
                <div className="uk-margin-left">
                  <a href="">
                    <img
                      src={require("../../../assets/img/appstore.png").default}
                      alt=""
                      width="102.5"
                      height="31"
                    />
                  </a>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div className="as-header-svg">
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style={{ height: "100%", width: "100%" }}
        >
          <linearGradient id="grad1" x1="100%" y1="0%" x2="100%" y2="200%">
            <stop
              offset="0%"
              style={{ stopColor: "rgb(251,42,73)", stopOpacity: 1 }}
            />
            <stop
              offset="100%"
              style={{ stopColor: "rgb(10,36,99)", stopOpacity: 1 }}
            />
          </linearGradient>
          <path
            d="M-1.97,149.01 C440.46,123.35 482.22,124.34 484.48,100.66 L500.00,0.00 L0.00,0.00 Z"
            style={{ stroke: "none", fill: "url(#grad1)" }}
          ></path>
        </svg>
      </div>

      <section className="uk-section uk-text-center as-margin-top-4">
        <h1 className="uk-h3 as-blue uk-text-bold">
          {t("HOME_PARKING_PROVID")}
        </h1>
        <p className="as-card">
          {t("HOME_PARKING_PROVID_DESC")
            .split("/n")
            .map((line) => (
              <p>{line}</p>
            ))}
        </p>
        <p className=" uk-margin-large-top">
          <a href="#" className="as-white">
            {" "}
            <span className="as-button-padding as-white uk-box-shadow-small uk-bo">
              {t("HOME_GET")}
            </span>{" "}
          </a>
        </p>
      </section>

      <section className="uk-section uk-container uk-text-center as-margin-top-3">
        <div
          className="uk-grid-small uk-child-width-expand@s uk-text-center"
          data-uk-grid
        >
          <div className="as-left as-circle-height">
            <div className="as-circleImg">
              <div className="as-circle uk-visible@s"></div>
              <div className="uk-position-relative uk-padding as-circle-cards">
                <div className="uk-grid-small uk-grid uk-child-expand@s uk-text-center">
                  <div
                    className="uk-width-1-2@s uk-width-1-1@s "
                    style={{ margin: "0 auto", backgroundColor: "r" }}
                  >
                    <div className="uk-card uk-card-default as-padding-card uk-border-rounded uk-height-medium">
                      <img
                        src={require("../../../assets/img/plane.png").default}
                        alt=""
                        height="50px"
                        width="50px"
                      />
                      <h4> {t("HOME_FIND_ICON")}</h4>
                      <p className="as-font-12">{t("HOME_FIND_ICON_DESC")}</p>
                    </div>
                    <div className="uk-card uk-card-default uk-margin-top as-padding-card uk-border-rounded uk-height-medium">
                      <img
                        src={
                          require("../../../assets/img/redPhone.png").default
                        }
                        alt=""
                        height="50px"
                        width="50px"
                      />
                      <h4>{t("HOME_RECHARGE")}</h4>
                      <p className="as-font-12">{t("HOME_RECHARGE_TEXT")}</p>
                    </div>
                  </div>
                  <div className="uk-width-1-2@s uk-width-1-1@s uk-margin-medium-top">
                    <div className="uk-card uk-card-default as-padding-card uk-border-rounded uk-height-medium">
                      <img
                        src={require("../../../assets/img/redBell.png").default}
                        alt=""
                        height="50px"
                        width="50px"
                      />
                      <h4>{t("HOME_STAY")}</h4>
                      <p className="as-font-12">{t("HOME_STAY_TEXT")}</p>
                    </div>
                    <div className="uk-card uk-card-default uk-margin-top as-padding-card uk-border-rounded uk-height-medium">
                      <img
                        src={require("../../../assets/img/redCall.png").default}
                        alt=""
                        height="50px"
                        width="50px"
                      />
                      <h4>{t("HOME_SUPPORT")}</h4>
                      <p
                        style={{
                          fontSize: "10pt",
                        }}
                      >
                        {t("HOME_SUPPORT_TEXT")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="as-circle-text">
            <div className="uk-margin-large-top">
              <h6 className="">{t("HOME_FEATURES")}</h6>
              <h1>
                <Trans i18nKey="HOME_EXPECT"></Trans>
              </h1>
              <h6 className="as-padding-right as-grey as-padding-right as-font-12">
                {t("HOME_EXPECTED_TEXT")}
              </h6>
            </div>
          </div>
        </div>
      </section>
      <section className="uk-margin-large-top as-woman uk-text-center as-margin-top">
        <div className="uk-container">
          <div className="uk-grid-small uk-child-width-expand@s " data-uk-grid>
            <div>
              <div className="uk-text-left uk-padding uk-margin-top as-left">
                <h4 className="uk-h4 uk-text-bold uk-text-left">
                  {" "}
                  {t("HOME_TOGETHER")}
                </h4>
                <p className="uk-text-left">{t("HOME_TOGETHER_TEXT")}</p>
              </div>
            </div>
            <div>
              <div className="uk-flex uk-flex-center@s uk-flex-right@m">
                <img
                  src={require("../../../assets/img/blackWoman.png").default}
                  className="test"
                  alt=""
                  width="250px"
                  height="450px"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="as-container-bg">
        <div
          className="uk-grid-small uk-child-width-expand@s uk-text-center as-container"
          data-uk-grid
        >
          <div>
            <div className="uk-text-left">
              <div className="uk-flex uk-flex-column uk-child-1-2@s uk-margin-large-top">
                <div className="uk-card uk-card-default uk-border-rounded as-card-padding">
                  <img
                    src={require("../../../assets/img/oval-pink.png").default}
                    alt=""
                  />
                  <p className="as-card uk-margin-medium-top">
                    {t("HOME_PHONE_TEXT_1")}
                  </p>
                </div>
                <div className="uk-card uk-card-default uk-margin-top uk-border-rounded as-card-padding">
                  <img
                    src={require("../../../assets/img/ovalLight.png").default}
                    alt=""
                  />
                  <p className="as-card uk-margin-medium-top">
                    {t("HOME_PHONE_TEXT_2")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="">
              <img
                src={require("../../../assets/img/iPhone_X.png").default}
                alt=""
              />
              <p className=" uk-margin-large-top">
                <a href="#" className="as-white uk-text-center">
                  {" "}
                  <span className="as-button-padding as-white uk-box-shadow-small uk-bo">
                    {t("HOME_PHONE_DOWNLOAD")}
                  </span>{" "}
                </a>
              </p>
            </div>
          </div>
          <div>
            <div className="uk-text-left">
              <div className="uk-flex uk-flex-column uk-child-1-2@s uk-margin-large-top">
                <div className="uk-card uk-card-default uk-border-rounded as-card-padding">
                  <img
                    src={require("../../../assets/img/clock.png").default}
                    alt=""
                  />
                  <p className="as-card uk-margin-medium-top">
                    {t("HOME_PHONE_TEXT_3")}
                  </p>
                </div>
                <div className="uk-card uk-card-default uk-margin-top uk-border-rounded as-card-padding">
                  <img
                    src={require("../../../assets/img/file.png").default}
                    alt=""
                  />
                  <p className="as-card uk-margin-medium-top">
                    {t("HOME_PHONE_TEXT_4")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="as-money uk-container uk-flex-center uk-margin-large-top uk-text-center s-margin-top-3">
        <div
          className="uk-grid-small uk-child-width-expand@s uk-text-center"
          data-uk-grid
        >
          <div className="as-left">
            <div className="uk-text-left uk-margin-xlarge-top uk-padding-small">
              <h6>{t("HOME_FEATURES")}</h6>
              <h1>
                <Trans i18nKey="HOME_NO_MONEY" />
              </h1>
              <h6 className=" as-grey as-padding-right as-font-12">
                {t("HOME_NO_MONEY_TEXT")}
              </h6>
            </div>
          </div>
          <div className="uk-visible@s">
            <img
              className="uk-flex uk-flex-center uk-center as-credit"
              src={require("../../../assets/img/iPhone_X Copy.png").default}
            />
            <div className="as-circleImg">
              <div className="as-circle"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section uk-text-center as-margin-top-3">
        <div className="uk-visible@s">
          <img
            className="uk-position-absolute"
            height="80px"
            width="80px"
            style={{ left: 0 }}
            src={require("../../../assets/img/ovalLeft.png").default}
            alt=""
          />
        </div>
        <h3 className="uk-padding-small">{t("HOME_PARKING")}</h3>
        <small>{t("HOME_PARKING_TEXT")}</small>
        <div className="uk-margin-large-top">
          <div
            className="uk-grid-column-small as-container-margin uk-grid-row-small uk-child-width-1-4@s uk-text-center"
            data-uk-grid
          >
            <div>
              <div className="">
                <img
                  src={require("../../../assets/img/img1.png").default}
                  alt=""
                />
              </div>
            </div>
            <div>
              <div className="">
                <img
                  src={require("../../../assets/img/img2.png").default}
                  alt=""
                />
              </div>
            </div>
            <div>
              <div className="">
                <img
                  src={require("../../../assets/img/img3.png").default}
                  alt=""
                />
              </div>
            </div>
            <div>
              <div className="">
                <img
                  src={require("../../../assets/img/img4.png").default}
                  alt=""
                />
              </div>
            </div>
            <div>
              <div className="">
                <img
                  src={require("../../../assets/img/img5.png").default}
                  alt=""
                />
              </div>
            </div>
            <div>
              <div className="">
                <img
                  src={require("../../../assets/img/img6.png").default}
                  alt=""
                />
              </div>
            </div>
            <div>
              <div className="">
                <img
                  src={require("../../../assets/img/img7.png").default}
                  alt=""
                />
              </div>
            </div>
            <div>
              <div className="">
                <img
                  src={require("../../../assets/img/img8.png").default}
                  alt=""
                />
              </div>
            </div>
          </div>
          <button className="uk-button uk-button-default uk-margin-top as-border-radius-curve uk-padding uk-padding-remove-vertical">
            {t("HOME_PARKING_SEE")}
          </button>
        </div>
      </section>

      <section className="uk-section uk-text-center">
        <h3>{t("HOME_SLIDER")}</h3>
        <div data-uk-slider>
          <div className="uk-visible@s">
            <img
              className="uk-position-absolute"
              height="80px"
              width="80px"
              style={{ right: 0 }}
              src={require("../../../assets/img/ovalRight.png").default}
              alt=""
            />
          </div>
          <div className="uk-position-relative  as-container-margin">
            <div className="uk-slider-container">
              <ul className="uk-slider-items uk-child-width-1-1@m">
                <li>
                  <div className="uk-panel">
                    <p className="as-grey3 uk-padding as-container-margin">
                      {t("HOME_SLIDER_TEXT")}
                    </p>
                    <article className="uk-comment uk-text-center uk-margin-top uk-position-relative uk-position-center">
                      <header className="uk-comment-header">
                        <div
                          className="uk-grid-medium uk-flex-middle"
                          data-uk-grid
                        >
                          <div className="uk-width-auto">
                            <img
                              className="uk-comment-avatar"
                              src={
                                require("../../../assets/img/story.png").default
                              }
                              width="80"
                              height="80"
                              alt=""
                            />
                          </div>
                          <div className="uk-width-expand">
                            <ul className="uk-comment-meta uk-subnav uk-margin-remove-top uk-margin-remove-bottom">
                              <li>
                                <a className="uk-text-capitalize" href="#">
                                  Author name
                                </a>
                              </li>
                              <br />
                            </ul>
                            <ul className="uk-subnav uk-margin-small-top ">
                              <li className="uk-padding-remove-right">
                                <a href="" uk-icon="icon: star; ratio: 0.8"></a>
                              </li>
                              <li className="uk-padding-remove-left">
                                <a href="" uk-icon="icon: star; ratio: 0.8"></a>
                              </li>
                              <li className="uk-padding-remove-left">
                                <i uk-icon="icon: star; ratio: 0.8"></i>
                              </li>
                              <li className="uk-padding-remove-left">
                                <a href="" uk-icon="icon: star; ratio: 0.8"></a>
                              </li>
                              <li className="uk-padding-remove-left">
                                <i uk-icon="icon: star; ratio: 0.8"></i>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </header>
                    </article>
                  </div>
                </li>
              </ul>
            </div>

            <div className="uk-hidden@s">
              <a
                className="uk-position-center-left uk-position-small"
                href="#"
                data-uk-slidenav-previous
                data-uk-slider-item="previous"
              ></a>
              <a
                className="uk-position-center-right uk-position-small"
                href="#"
                data-uk-slidenav-next
                data-uk-slider-item="next"
              ></a>
            </div>

            <div className="uk-visible@s">
              <a
                className="uk-position-center-left uk-position-small uk-box-shadow-small uk-border-circle"
                href="#"
                data-uk-slidenav-previous
                data-uk-slider-item="previous"
              ></a>
              <a
                className="uk-position-center-right uk-position-small uk-box-shadow-small uk-border-circle"
                href="#"
                data-uk-slidenav-next
                data-uk-slider-item="next"
              ></a>
            </div>

            <ul className="uk-slider-nav uk-dotnav uk-hidden@m uk-flex-center uk-margin"></ul>
          </div>
        </div>
      </section>
    </Fragment>
  );
}
export default Home;
