import React, { Fragment, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";

function Prices() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Fragment>
      <section>
        <div
          className="uk-grid-small uk-margin-xlarge-top uk-child-width-expand@s"
          data-uk-grid
        >
          <div className="uk-padding uk-container uk-margin-xlarge-top uk-hidden@s">
            <p className="">
              <Trans i18nKey="PRICE_STOP" />
            </p>
            <h2 className="uk-text-bold">
              <Trans i18nKey="PRICE_TIRED" />
            </h2>
            <small>{t("PRICE_DISCOVER")}</small>
            <p className="">
              <a href="#" className="as-white">
                {" "}
                <span className="as-button-padding as-white uk-box-shadow-small uk-bo">
                  {t("PRICE_GET")}
                </span>{" "}
              </a>
            </p>
          </div>
          <div className="uk-padding uk-container uk-margin-xlarge-top uk-margin-xlarge-left uk-visible@s">
            <p className="">
              {" "}
              <Trans i18nKey="PRICE_STOP" />
            </p>
            <h2 className="uk-text-bold">
              <Trans i18nKey="PRICE_TIRED" />
            </h2>
            <small>{t("PRICE_DISCOVER")}</small>
            <p className="">
              <a href="#" className="as-white">
                {" "}
                <span className="as-button-padding as-white uk-box-shadow-small uk-bo">
                  {t("PRICE_GET")}
                </span>{" "}
              </a>
            </p>
          </div>

          <div className="uk-visible@s">
            <img
              className="uk-position-absolute as-header-pos"
              src={require("../../../assets/img/circles.png").default}
              alt=""
              data-uk-contain
              width="40%"
            />
            <img
              className="uk-position-relative as-manPhone"
              alt=""
              src={require("../../../assets/img/maps.png").default}
              width="60%"
              style={{ left: "13%", top: "5%" }}
            />
            <div className="">
              <img
                className="as-rounded-img uk-border-circle uk-box-shadow-large"
                src={require("../../../assets/img/roundMan.png").default}
                alt=""
                width="15%"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="as-benefit uk-container uk-section uk-flex-center">
        <div
          className="uk-grid-small uk-child-width-expand@s uk-text-center"
          data-uk-grid
        >
          <div className="uk-padding as-left-small uk-visible@s">
            <div
              className="uk-background-contain as-bg-img-1"
              style={{
                backgroundImage: `url(${
                  require("../../../assets/img/phoneRight.png").default
                })`,
              }}
            ></div>
          </div>
          <div className="uk-text-center@m uk-padding">
            <div className="uk-text-left uk-margin-large-top">
              <h5 className=""> {t("PRICE_PAYMENT")}</h5>
              <h1 className="uk-text-bold">
                <Trans i18nKey="PRICE_PAYMENT_TEXT" />
              </h1>
              <p className="">{t("PRICE_PAYMENT_AVOID")}</p>
              <div className="as-padding-right as-grey as-font-12">
                <p>{t("PRICE_PAYMENT_AVOID_TEXT")}</p>
                <p>{t("PRICE_PAYMENT_AVOID_TEXT_NO")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="as-intelligent uk-container uk-section uk-flex-center ">
        <div
          className="uk-grid-small uk-child-width-expand@s uk-text-left"
          data-uk-grid
        >
          <div className="as-left uk-padding">
            <div className="uk-margin-xlarge-top">
              <h1 className="uk-text-bold uk-text-uppercase">
                <Trans i18nKey="PRICE_IN" />
              </h1>
              <div className="as-grey as-font-12 uk-padding-small">
                <span className="as-active as-intel">
                  <img
                    src={require("../../../assets/img/location.svg").default}
                    className="uk-text-left"
                    alt=""
                    width="20px"
                    height="20px"
                  />
                </span>

                <span className="uk-margin-small-left">
                  {" "}
                  {t("PRICE_IN_TEXT")}
                </span>
              </div>
              <div className="as-grey as-font-12 uk-padding-small">
                <span className="as-intel">
                  <img
                    src={require("../../../assets/img/pie.svg").default}
                    className="uk-text-left"
                    alt=""
                    width="20px"
                    height="20px"
                  />
                </span>
                <span className="uk-margin-small-left">
                  {t("PRICE_IN_TEXT_REL")}
                </span>
              </div>
              <div className="as-grey as-font-12 uk-padding-small">
                <span className="as-intel">
                  <img
                    src={require("../../../assets/img/bell.svg").default}
                    className="uk-text-left"
                    alt=""
                    width="20px"
                    height="20px"
                  />
                </span>
                <span className="uk-margin-small-left">
                  {" "}
                  {t("PRICE_IN_TEXT_REC")}
                </span>
              </div>
            </div>
          </div>
          <div className="as-right-small uk-padding uk-visible@s uk-margin-medium-top">
            <div className="">
              <img
                className="uk-position-absolute as-maps"
                src={require("../../../assets/img/maps.png").default}
                alt=""
                width="60%"
              />
              <img
                className="uk-position-absolute"
                src={
                  require("../../../assets/img/happy-driver-looking-phone_23-2148509014.png")
                    .default
                }
                alt=""
                width="90%"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="uk-container uk-section as-container-bg uk-margin-remove-top as-margin-top-3">
        <h1 className="uk-text-uppercase uk-text-center">
          {t("PRICE_DESCOVERY")}
        </h1>
        <div className="uk-margin-medium-top">
          <div
            className="uk-grid-small uk-child-width-1-2@s uk-child-width-1-4@m  uk-text-center as-container"
            data-uk-grid
          >
            <div>
              <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-height-medium">
                <img
                  src={require("../../../assets/img/truck.png").default}
                  alt=""
                  width="60px"
                  height="60px"
                />
                <p>{t("PRICE_DESCOVERY_PARK")}</p>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-height-medium">
                <img
                  src={require("../../../assets/img/settings.png").default}
                  alt=""
                  width="60px"
                  height="60px"
                />
                <p>{t("PRICE_DESCOVERY_START")}</p>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-height-medium">
                <img
                  src={require("../../../assets/img/noteYellow.png").default}
                  alt=""
                  width="60px"
                  height="60px"
                />
                <p>{t("PRICE_DESCOVERY_TICKET")}</p>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-height-medium">
                <img
                  src={require("../../../assets/img/noteGreen.png").default}
                  alt=""
                  width="60px"
                  height="60px"
                />
                <p>{t("PRICE_DESCOVERY_CONSULT")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="uk-container uk-section">
        <h1 className="uk-text-uppercase uk-text-center">
          {t("PRICE_OFFERS")}
        </h1>
        <p className="uk-text-center">{t("PRICE_OFFERS_TEXT")}</p>
        <div className="uk-margin-large-top">
          <div
            className="uk-grid-collapse uk-child-width-1-2@s uk-child-width-1-3@m  uk-text-center as-container"
            data-uk-grid
          >
            <div>
              <div className="uk-card uk-card-default uk-card-body uk-border-rounded">
                <h5 className="uk-text-center uk-margin-remove-bottom">
                  {t("PRICE_OFFERS_TEAM")}
                </h5>
                <h2 className="uk-text-bold uk-text-center uk-margin-remove-top">
                  {t("PRICE_2000")}{" "}
                  <span className="as-font-12">{t("PRICE_FRS")}</span>
                </h2>
                <div>
                  <ul className="uk-text-left uk-list">
                    <li className="uk-margin-top">
                      <img
                        src={
                          require("../../../assets/img/greenCheck.png").default
                        }
                        className="uk-margin-small-right"
                        alt=""
                        width="20px"
                        height="20px"
                      />{" "}
                      {t("PRICE_SPACE_500")}
                    </li>
                    <li className="uk-margin-top">
                      <img
                        src={
                          require("../../../assets/img/greenCheck.png").default
                        }
                        className="uk-margin-small-right"
                        alt=""
                        width="20px"
                        height="20px"
                      />{" "}
                      {t("PRICE_EMAIL_100")}
                    </li>
                    <li className="uk-margin-top as-grey3">
                      <img
                        src={
                          require("../../../assets/img/redCross.png").default
                        }
                        className="uk-margin-small-right"
                        alt=""
                        width="20px"
                        height="20px"
                      />{" "}
                      {t("PRICE_UNLIMITED_BANDWIDTH")}
                    </li>
                    <li className="uk-margin-top as-grey3">
                      <img
                        src={
                          require("../../../assets/img/redCross.png").default
                        }
                        className="uk-margin-small-right"
                        alt=""
                        width="20px"
                        height="20px"
                      />{" "}
                      {t("PRICE_WRITE")}
                    </li>
                    <li className="uk-margin-top as-grey3">
                      <img
                        src={
                          require("../../../assets/img/redCross.png").default
                        }
                        className="uk-margin-small-right"
                        alt=""
                        width="20px"
                        height="20px"
                      />{" "}
                      {t("PRICE_UNLIMITED_BANDWIDTH_2")}
                    </li>
                  </ul>
                  <br />
                  <a href="#" className="as-white">
                    {" "}
                    <span className="as-button-padding as-bg-white uk-box-shadow-small uk-margin-top">
                      {t("PRICE_GET")}
                    </span>{" "}
                  </a>
                </div>
              </div>
            </div>
            <div>
              <div className="">
                <div
                  className="uk-background-cover as-red-card uk-border-rounded uk-box-shadow-large uk-panel uk-padding"
                  style={{
                    backgroundImage: `url(${
                      require("../../../assets/img/redCard.png").default
                    })`,
                  }}
                >
                  <h5 className="as-white uk-text-center uk-margin-remove-bottom">
                    {t("PRICE_PREMIUM")}
                  </h5>
                  <h2 className="as-white uk-text-bold uk-text-center uk-margin-remove-top">
                    {t("PRICE_1000")}{" "}
                    <span className="as-font-12">{t("PRICE_FRS")}</span>
                  </h2>
                  <div>
                    <ul className="uk-text-left uk-list as-white">
                      <li className="uk-margin-top">
                        <img
                          src={
                            require("../../../assets/img/whiteCheck.png")
                              .default
                          }
                          className="uk-margin-small-right"
                          alt=""
                          width="20px"
                          height="20px"
                        />{" "}
                        {t("PRICE_SPACE_500")}
                      </li>
                      <li className="uk-margin-top">
                        <img
                          src={
                            require("../../../assets/img/whiteCheck.png")
                              .default
                          }
                          className="uk-margin-small-right"
                          alt=""
                          width="20px"
                          height="20px"
                        />{" "}
                        {t("PRICE_EMAIL_100")}
                      </li>
                      <li className="uk-margin-top">
                        <img
                          src={
                            require("../../../assets/img/whiteCheck.png")
                              .default
                          }
                          className="uk-margin-small-right"
                          alt=""
                          width="20px"
                          height="20px"
                        />{" "}
                        {t("PRICE_UNLIMITED_BANDWIDTH")}
                      </li>
                      <li className="uk-margin-top">
                        <img
                          src={
                            require("../../../assets/img/whiteCheck.png")
                              .default
                          }
                          className="uk-margin-small-right"
                          alt=""
                          width="20px"
                          height="20px"
                        />{" "}
                        {t("PRICE_WRITE")}
                      </li>
                      <li className="uk-margin-top">
                        <img
                          src={
                            require("../../../assets/img/whiteCheck.png")
                              .default
                          }
                          className="uk-margin-small-right"
                          alt=""
                          width="20px"
                          height="20px"
                        />{" "}
                        {t("PRICE_UNLIMITED_BANDWIDTH_2")}
                      </li>
                    </ul>
                    <br />
                    <a href="#" className="as-red">
                      {" "}
                      <span className="as-button-padding-white as-bg-white uk-box-shadow-small uk-margin-top">
                        {t("PRICE_GET")}
                      </span>{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-default uk-card-body uk-border-rounded">
                <h5 className="uk-text-center uk-margin-remove-bottom">
                  {t("PRICE_TITLE_3")}
                </h5>
                <h2 className="uk-text-bold uk-text-center uk-margin-remove-top">
                  {t("PRICE_TITLE_3_2000")}{" "}
                  <span className="as-font-12">{t("PRICE_FRS")}</span>
                </h2>
                <div>
                  <ul className="uk-text-left uk-list">
                    <li className="uk-margin-top">
                      <img
                        src={
                          require("../../../assets/img/greenCheck.png").default
                        }
                        className="uk-margin-small-right"
                        alt=""
                        width="20px"
                        height="20px"
                      />{" "}
                      {t("PRICE_SPACE_500")}
                    </li>
                    <li className="uk-margin-top">
                      <img
                        src={
                          require("../../../assets/img/greenCheck.png").default
                        }
                        className="uk-margin-small-right"
                        alt=""
                        width="20px"
                        height="20px"
                      />{" "}
                      {t("PRICE_EMAIL_100")}
                    </li>
                    <li className="uk-margin-top as-grey3">
                      <img
                        src={
                          require("../../../assets/img/greenCheck.png").default
                        }
                        className="uk-margin-small-right"
                        alt=""
                        width="20px"
                        height="20px"
                      />{" "}
                      {t("PRICE_UNLIMITED_BANDWIDTH")}
                    </li>
                    <li className="uk-margin-top as-grey3">
                      <img
                        src={
                          require("../../../assets/img/greenCheck.png").default
                        }
                        className="uk-margin-small-right"
                        alt=""
                        width="20px"
                        height="20px"
                      />{" "}
                      {t("PRICE_WRITE")}
                    </li>
                    <li className="uk-margin-top as-grey3">
                      <img
                        src={
                          require("../../../assets/img/greenCheck.png").default
                        }
                        className="uk-margin-small-right"
                        alt=""
                        width="20px"
                        height="20px"
                      />{" "}
                      {t("PRICE_UNLIMITED_BANDWIDTH_2")}
                    </li>
                  </ul>
                  <br />
                  <a href="#" className="as-white">
                    {" "}
                    <span className="as-button-padding as-bg-white uk-box-shadow-small uk-margin-top">
                      {t("PRICE_GET")}
                    </span>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </Fragment>
  );
}
export default Prices;
