import React, { Fragment, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Link } from "react-router-dom";

const key = "This is wadparklogin-key";

const CryptoJS = require("crypto-js");

function Login() {
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [step, setStep] = useState<number>(1);
  const [successf, setSuccessF] = useState<string>("");

  const formik = useFormik({
    initialValues: {
      password: "",
      username: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "Minimum 8 characters")
        .required("Required!"),
      username: Yup.string()
        .min(1, "Minimum 1 characters")
        .required("Required!"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      axios({
        method: "post",
        url: "auth/login/",
        data: values,
      })
        .then((data: any) => {
          const ciphertext = CryptoJS.AES.encrypt(
            JSON.stringify({
              password: values.password,
              email: values.username,
            }),
            key
          ).toString();

          console.log(ciphertext);
          console.log(`http://admin.wadpark.com/login?park="${ciphertext}"`);
          window.location.href = `http://admin.wadpark.com/login?park="${ciphertext}"`;

          // Should print gibberish:

          setLoading(false);
        })
        .catch((err: any) => {
          setMessage(err.response.data.detail);

          setLoading(false);
        });
    },
  });

  const formikRequest = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Required!"),
    }),
    onSubmit: (values) => {
      console.log(values);
      setLoading(true);
      axios({
        method: "post",
        url: "accounts/password/reset/",
        data: values,
      })
        .then((data: any) => {
          setSuccessF("");
          setMessage("");
          //console.log(Object.keys(data));
          setSuccessF(data.data.detail);
          setLoading(false);
        })
        .catch((err: any) => {
          setSuccessF("");
          setMessage("");

          setMessage(err.response.data.detail);

          setLoading(false);
        });
    },
  });
  if (step === 1) {
    return (
      <Fragment>
        <section className="as-bg-gradient">
          <br />
          <br />
          <br />
          <br />
          <br />
          <div className="">
            <div
              className="uk-text-center uk-grid-collapse uk-flex uk-flex-center as-container uk-padding"
              data-uk-grid
            >
              <div className="uk-width-1-2@m uk-text-left">
                <div className="uk-card uk-card-default uk-card-body uk-border-rounded as-bg-height">
                  <h4 className="uk-h4">Sign In</h4>
                  <form
                    onSubmit={formik.handleSubmit}
                    className="uk-grid-small"
                    data-uk-grid
                  >
                    <div className="uk-width-1-1">
                      <input
                        className="uk-input"
                        type="text"
                        placeholder="Username"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        name="username"
                      />
                      {formik.errors.username && formik.touched.username && (
                        <p className="uk-text-danger">
                          {formik.errors.username}
                        </p>
                      )}
                    </div>
                    <div className="uk-width-1-1">
                      <input
                        className="uk-input"
                        type="text"
                        placeholder="Password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.password && formik.touched.password && (
                        <p className="uk-text-danger">
                          {formik.errors.password}
                        </p>
                      )}
                    </div>
                    <div className="uk-width-1-1">
                      <button className="uk-button uk-button-default as-bg-red uk-border-rounded as-white uk-width-1-1  uk-text-capitalize">
                        join wadPack
                      </button>
                    </div>
                    {loading && (
                      <div className="uk-width-1-1 uk-flex  uk-flex-center">
                        <span
                          className="uk-margin-small-right"
                          uk-spinner="ratio: 2"
                        ></span>
                      </div>
                    )}
                    <div className="uk-width-1-1">
                      {message && <p className="uk-text-danger">{message}</p>}
                    </div>
                    <div className="">
                      <p>
                        <a
                          onClick={() => {
                            setStep(2);
                          }}
                          className="as-red"
                        >
                          {" "}
                          Forgot password
                        </a>
                      </p>

                      <br />
                      <br />
                      <br />
                      <p>
                        <span className="uk-flex uk-flex-left uk-text-left">
                          New to WadPark ?{" "}
                          <Link to="/register" className="as-red">
                            {" "}
                            Register
                          </Link>
                        </span>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />

          <br />
          <br />
          <br />

          <br />
          <br />
          <br />
        </section>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <section className="as-bg-gradient">
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="">
          <div
            className="uk-text-center uk-grid-collapse uk-flex uk-flex-center as-container uk-padding"
            data-uk-grid
          >
            <div className="uk-width-1-2@m uk-text-left">
              <div className="uk-card uk-card-default uk-card-body uk-border-rounded as-bg-height">
                <h4 className="uk-h4">Forgot Password</h4>
                <form
                  onSubmit={formikRequest.handleSubmit}
                  className="uk-grid-small"
                  data-uk-grid
                >
                  <div className="uk-width-1-1">
                    <input
                      className="uk-input"
                      type="text"
                      placeholder="Email"
                      value={formikRequest.values.email}
                      onChange={formikRequest.handleChange}
                      name="email"
                    />
                    {formikRequest.errors.email &&
                      formikRequest.touched.email && (
                        <p className="uk-text-danger">
                          {formikRequest.errors.email}
                        </p>
                      )}
                  </div>

                  <div className="uk-width-1-1">
                    <button className="uk-button uk-button-default as-bg-red uk-border-rounded as-white uk-width-1-1  uk-text-capitalize">
                      Send Password
                    </button>
                  </div>
                  {loading && (
                    <div className="uk-width-1-1 uk-flex  uk-flex-center">
                      <span
                        className="uk-margin-small-right"
                        uk-spinner="ratio: 2"
                      ></span>
                    </div>
                  )}
                  <div className="uk-width-1-1">
                    {message && <p className="uk-text-danger">{message}</p>}
                  </div>
                  <div className="uk-width-1-1">
                    {successf && <p className="uk-text-success">{successf}</p>}
                  </div>
                  <div className="">
                    <p>
                      <a
                        onClick={() => {
                          setStep(1);
                        }}
                        className="as-red"
                      >
                        Back to login
                      </a>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br /> <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </section>
    </Fragment>
  );
}
export default Login;
