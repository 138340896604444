import React, { Fragment, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useTranslation, Trans } from "react-i18next";

function Contact() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [step, setStep] = useState<number>(1);
  const [success, setSuccess] = useState<string>("");

  const formik = useFormik({
    initialValues: {
      email: "",
      nom: "",
      message: "",
    },
    validationSchema: Yup.object({
      nom: Yup.string().required("ce champ est obligatoire*"),
      email: Yup.string().email().required("ce champ est obligatoire*"),
      message: Yup.string().required("ce champ est obligatoire*"),
    }),
    onSubmit: (values) => {
      const contactForm = {
        first_name: values.nom,
        last_name: values.nom,
        email: values.email,
        message: values.message,
        phone: values.nom,
      };
      setLoading(true);
      axios({
        method: "post",
        url: "contacts/us/",
        data: contactForm,
      })
        .then((data: any) => {
          setLoading(false);
          setSuccess(
            "Votre message a été envoyé, nous vous contacterons dans les prochains jours."
          );
        })
        .catch((err: any) => {
          setMessage(err.response.data.detail);

          setLoading(false);
        });
    },
  });

  return (
    <Fragment>
      <section className="uk-margin-large-top">
        <br />
        <br />
        <div className="uk-padding uk-container">
          <div
            className=" uk-child-width-1-2@s uk-child-width-expand@m uk-text-center uk-padding"
            data-uk-grid
            uk-height-match="target: > div > .uk-card"
          >
            <div>
              <div className="uk-card uk-card-body as-bg-grey4 uk-border-rounded">
                <h2>Address</h2>
                <p className="as-dark">BP : 5613 Douala Akwa-Cameroun</p>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-body as-bg-grey4 uk-border-rounded">
                <h2>Phone</h2>
                <p className="as-dark">(+237) 243 100 010 / 691 50 00 75 </p>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-body as-bg-grey4 uk-border-rounded">
                <h2>Email</h2>
                <p className="as-dark">sales@astorprotect.com</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section uk-text-center uk-container">
        <h1 className="uk-h2 uk-text-uppercase">DROP A LINE</h1>
        <p className="as-card">Get in Touch</p>
        <div
          className="uk-child-width-expand@s uk-grid-small uk-grid-match uk-padding uk-padding-remove-vertical"
          data-uk-grid
        >
          <form
            onSubmit={formik.handleSubmit}
            className="uk-grid-small as-form-rounded"
            data-uk-grid
          >
            <div className="uk-width-1-2@s">
              <input
                className="uk-input"
                type="text"
                placeholder="Nom et prénoms"
                value={formik.values.nom}
                onChange={formik.handleChange}
                name="nom"
              />
              {formik.errors.nom && formik.touched.nom && (
                <p className="uk-text-danger">{formik.errors.nom}</p>
              )}
            </div>
            <div className="uk-width-1-2@s">
              <input
                className="uk-input"
                type="text"
                placeholder="Your email"
                value={formik.values.email}
                onChange={formik.handleChange}
                name="email"
              />
              {formik.errors.email && formik.touched.email && (
                <p className="uk-text-danger">{formik.errors.email}</p>
              )}
            </div>
            <div className="uk-margin">
              <textarea
                className="uk-textarea"
                rows={5}
                placeholder="Message*"
                value={formik.values.message}
                onChange={formik.handleChange}
                name="message"
              ></textarea>

              {formik.errors.message && formik.touched.message && (
                <p className="uk-text-danger">{formik.errors.message}</p>
              )}
            </div>

            <div className="uk-padding">
              <p className="uk-margin-top uk-text-right">
                <button className="as-button-padding as-white uk-box-shadow-small">
                  Send Message
                </button>
              </p>
            </div>
          </form>
        </div>

        <div className="uk-width-1-1">
          {success && <p className="uk-text-success">{success}</p>}
        </div>
        {loading && (
          <div className="uk-width-1-1 uk-flex  uk-flex-center">
            <span
              className="uk-margin-small-right"
              uk-spinner="ratio: 2"
            ></span>
          </div>
        )}
        <div className="uk-width-1-1">
          {message && <p className="uk-text-danger">{message}</p>}
        </div>
      </section>
    </Fragment>
  );
}
export default Contact;
