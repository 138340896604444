import React from "react";
import Router from "./route";
import UIKit from "./utils/uikit";
import MessengerCustomerChat from "react-messenger-customer-chat";
import "./i18n";
import "./redux/config";
function App() {
  return (
    <UIKit>
      <Router />
      <MessengerCustomerChat pageId="102147178814739" appId="592867458787565" />
    </UIKit>
  );
}

export default App;

// <!-- Messenger Plugin de discussion Code -->
//     <div id="fb-root"></div>

//     <!-- Your Plugin de discussion code -->
//     <div id="fb-customer-chat" class="fb-customerchat">
//     </div>

// <script>
//   var chatbox = document.getElementById('fb-customer-chat');
//   chatbox.setAttribute("page_id", "102147178814739");
//   chatbox.setAttribute("attribution", "biz_inbox");

//   window.fbAsyncInit = function() {
//     FB.init({
//       xfbml            : true,
//       version          : 'v11.0'
//     });
//   };

//   (function(d, s, id) {
//     var js, fjs = d.getElementsByTagName(s)[0];
//     if (d.getElementById(id)) return;
//     js = d.createElement(s); js.id = id;
//     js.src = 'https://connect.facebook.net/fr_FR/sdk/xfbml.customerchat.js';
//     fjs.parentNode.insertBefore(js, fjs);
//   }(document, 'script', 'facebook-jssdk'));
// </script>
