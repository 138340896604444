import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import fr from "./fr.json";
import en from "./en.json";

i18n.use(initReactI18next).init({
  fallbackLng: "fr",
  resources: {
    fr: {
      translation: fr,
    },
    en: {
      translation: en,
    },
  },
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["br", "li", "small", "span"],
  },
});

export { i18n };
