import React, { Fragment, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";

function Privacy() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Fragment>
      <section className="uk-container as-privacy-section">
        <br />
        <br />
        <br />
        <br />
        <h1 className="uk-text-bold uk-text-center">Privacy Policy</h1>
        <div className=" uk-padding">
          <div className="uk-grid-small uk-child-width-expand@s uk-margin-medium-top uk-border-rounded as-privacy-card">
            <div className="">
              <div className="uk-text-left" data-uk-grid>
                <div className="uk-width-1-3@m">
                  <div className="uk-card uk-card-body as-bg-grey3 as-privacy-card-left">
                    <ul className="">
                      <li>
                        <a href="" className="">
                          Nos Services
                        </a>
                      </li>
                      <li>
                        <a href="">
                          {" "}
                          Quelles données personnelles collectons-nous ?
                        </a>
                      </li>
                      <li>
                        <a href="">
                          Stationnement sans inscription en tant qu’utilisateur
                        </a>
                      </li>
                      <li>
                        <a href="">Cookies et autres technologie similaires</a>
                      </li>
                      <li>
                        <a href="">Appareil et emplacement</a>
                      </li>
                      <li>
                        <a href="">
                          Avec qui partageons-nous vos données personnelles ?
                        </a>
                      </li>
                      <li>
                        <a href="">
                          Où vos données personnelles sont-elles traitées ?
                        </a>
                      </li>
                      <li>
                        <a href="">
                          Combien de temps conservons-nous vos données
                          personnelles ?
                        </a>
                      </li>
                      <li>
                        <a href="">
                          Accès et gestion de vos données personnelles
                        </a>
                      </li>
                      <li>
                        <a href="">Entité responsable</a>
                      </li>
                      <li>
                        <a href="">
                          WAD PARK GROUPE Conditions Générales de Vente et
                          d’Utilisation
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="uk-width-expand@m">
                  <div className="uk-card uk-card-body as-privacy-card-right">
                    <p>
                      {" "}
                      Votre confidentialité est importante pour nous chez WAD
                      PARK. Nous n’utiliserons vos informations personnelles que
                      pour administrer votre compte, fournir les produits et
                      services que vous nous avez demandés et vous tenir informé
                      de nos produits et services. La protection, la
                      confidentialité et l’intégrité de vos données personnelles
                      sont nos principales préoccupations. Cet avis de
                      confidentialité explique quelles sont les informations
                      personnelles que nous collectons lorsque vous interagissez
                      avec nos produits et services, ainsi que la manière dont
                      nous utilisons ces informations.
                    </p>

                    <h5>Nos Services</h5>

                    <p>
                      WAD PARK fournit une solution pour payer vos sessions de
                      stationnement, sur la voirie et hors voirie, les
                      plates-formes que sont un site Web et une application
                      mobile. En outre, nous fournissons également des services
                      liés aux abonnements de stationnement. Le présent Avis de
                      Confidentialité s’applique à ces services et aux
                      plates-formes correspondantes. Quelles données
                      personnelles collectons-nous ?
                    </p>

                    <p>
                      Nous avons besoin de certaines informations personnelles
                      pour pouvoir vous offrir la meilleure expérience de
                      stationnement et vous tenir informés de nos produits et
                      services. Vous fournissez certaines de ces données
                      directement, par exemple lorsque vous créez un compte ou
                      entrez vos informations de stationnement dans notre
                      application mobile. De plus, nous recevons indirectement
                      certaines de vos données personnelles via vos appareils,
                      en enregistrant la façon dont vous interagissez avec nos
                      services (par ex. via des cookies). Cela nous permet de
                      personnaliser et d’améliorer nos produits.
                    </p>

                    <h5>Quelles données personnelles collectons-nous ?</h5>

                    <p>
                      Nous avons besoin de certaines informations personnelles
                      pour pouvoir vous offrir la meilleure expérience de
                      stationnement et vous tenir informés de nos produits et
                      services. Vous fournissez certaines de ces données
                      directement, par exemple lorsque vous créez un compte ou
                      entrez vos informations de stationnement dans notre
                      application mobile. De plus, nous recevons indirectement
                      certaines de vos données personnelles via vos appareils,
                      en enregistrant la façon dont vous interagissez avec nos
                      services (par ex. via des cookies). Cela nous permet de
                      personnaliser et d’améliorer nos produits. Lorsque vous
                      créez un compte WAD PARK, démarrez une session de
                      stationnement ou utilisez nos services, nous traitons les
                      détails suivants :
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}
export default Privacy;
