import React, { Fragment, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";

function Partner() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Fragment>
      <section>
        <div
          className="uk-grid-small uk-margin-xlarge-top uk-child-width-expand@s"
          data-uk-grid
        >
          <div className="uk-padding uk-container uk-margin-xlarge-top uk-margin-xlarge-left">
            <p className="as-red text-uppercase"> WAD PARK </p>
            <h2 className="uk-text-bold uk-margin-remove-top">
              <Trans i18nKey="PARTNER_TITLE"></Trans>
            </h2>
            <div className="as-border-red-left uk-padding-left">
              <div className="uk-margin-left">
                <small>
                  <Trans i18nKey="PARTNER_SUBTITLE"></Trans>
                </small>
              </div>
            </div>
          </div>
          <div className="uk-visible@s">
            <img
              className="uk-position-absolute"
              src={require("../../../assets/img/circles3.png").default}
              alt=""
              data-uk-contain
              width="30%"
            />
            <img
              className="uk-position-relative as-manPhone"
              src={require("../../../assets/img/girlWo.png").default}
              alt=""
              width="60%"
              style={{ left: "13%", top: "5%" }}
            />
          </div>
        </div>
      </section>

      <section className="uk-section uk-text-center ">
        <h1 className="uk-h3 as-blue uk-text-bold">{t("PARTNER_CREATE")}</h1>
        <p className="as-card">
          <Trans i18nKey="PARTNER_CREATE_WE"></Trans>
        </p>
        <p className=" uk-margin-large-top">
          <a href="#" className="as-white">
            {" "}
            <span className="as-button-padding as-white uk-box-shadow-small uk-bo">
              {t("PARTNER_CREATE_GET_STARTED")}
            </span>{" "}
          </a>
        </p>
      </section>

      <section className="as-qr uk-section uk-container uk-flex-center uk-margin-top">
        <div className="uk-text-center uk-visible@m">
          <img
            className="uk-position-absolute as-z-index-minus"
            height="800px"
            width="340px"
            src={require("../../../assets/img/greyCircle.png").default}
            style={{ left: "0" }}
            alt=""
          />
        </div>
        <div
          className="uk-child-width-1-2@m uk-grid-small uk-grid-match"
          data-uk-grid
        >
          <div></div>

          <div>
            <div className="uk-card uk-card-body">
              <h3> {t("PARTNER_WHY_JOIN")}</h3>
              <p>
                <Trans i18nKey="PARTNER_WHY_TEXT"></Trans>
              </p>
              <p>{t("PARTNER_WHY_SUB_TEXT")}</p>
            </div>
          </div>
        </div>
      </section>

      <section
        className="uk-section uk-margin-large-top"
        style={{
          backgroundImage: `url(${
            require("../../../assets/img/siteInLap.png").default
          })`,
          backgroundPosition: " 100% 100%",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="uk-container">
          <div
            className="uk-child-width-1-2@m uk-child-width-1-1@s uk-grid-small uk-grid-match"
            data-uk-grid
          >
            <div>
              <div className="uk-card uk-card-body">
                <div className="uk-grid-medium uk-grid" data-uk-grid="">
                  <div className="uk-width-auto uk-first-column">
                    <p className="as-font-40 as-grey3 uk-text-bold">01</p>
                  </div>
                  <div className="uk-width-expand">
                    <h4 className="uk-text-bold">{t("PARTNER_SYSTEM")}</h4>
                    <p>{t("PARTNER_SYSTEM_TEXT")}</p>
                  </div>
                </div>
                <div className="uk-grid-medium uk-grid" data-uk-grid="">
                  <div className="uk-width-auto uk-first-column">
                    <p className="as-font-40 as-grey3 uk-text-bold">02</p>
                  </div>
                  <div className="uk-width-expand">
                    <h4 className="uk-text-bold">{t("PARTNER_TRAINING")}</h4>
                    <p>{t("PARTNER_TRAINING_TEXT")}</p>
                  </div>
                </div>

                <div className="uk-grid-medium uk-grid" data-uk-grid="">
                  <div className="uk-width-auto uk-first-column">
                    <p className="as-font-40 as-grey3 uk-text-bold">03</p>
                  </div>
                  <div className="uk-width-expand">
                    <h4 className="uk-text-bold">{t("PARTNER_SALES")}</h4>
                    <p>{t("PARTNER_SALES_TEXT")}</p>
                  </div>
                </div>

                <div className="uk-grid-medium uk-grid" data-uk-grid="">
                  <div className="uk-width-auto uk-first-column">
                    <p className="as-font-40 as-grey3 uk-text-bold">04</p>
                  </div>
                  <div className="uk-width-expand">
                    <h4 className="uk-text-bold">{t("PARTNER_TECHNICAL")}</h4>
                    <p> {t("PARTNER_TECHNICAL_TEXT")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section as-bg-grey3">
        <div className="uk-container">
          <h3 className="uk-text-center">{t("PARTNER_TECHNOLOGY_TEXT")}</h3>
          <p className="as-red uk-text-center">
            {t("PARTNER_TECHNOLOGY_TEXT_SUB")}
          </p>
          <div
            className="uk-grid-small uk-child-width-expand@s uk-text-center as-container"
            data-uk-grid
          >
            <div>
              <div className="uk-text-left">
                <div className="uk-flex uk-flex-column uk-child-1-2@s uk-margin-large-top">
                  <div className="uk-card  uk-border-rounded as-card-padding">
                    <div className="uk-grid-medium uk-grid" data-uk-grid="">
                      <div className="uk-width-auto uk-first-column">
                        <img
                          src={
                            require("../../../assets/img/filePi.png").default
                          }
                          alt=""
                        />
                      </div>
                      <div className="uk-width-expand">
                        <h5 className="uk-text-bold">
                          {t("PARTNER_TECHNOLOGY_PROFITABLILTY")}
                        </h5>
                        <p>{t("PARTNER_TECHNOLOGY_PROFITABLILTY_TEXT")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="uk-card  uk-border-rounded as-card-padding">
                    <div className="uk-grid-medium uk-grid" data-uk-grid="">
                      <div className="uk-width-auto uk-first-column">
                        <img
                          src={
                            require("../../../assets/img/settingPi.png").default
                          }
                          alt=""
                        />
                      </div>
                      <div className="uk-width-expand">
                        <h5 className="uk-text-bold">
                          {t("Full integration")}
                        </h5>
                        <p>{t("PARTNER_TECHNOLOGY_FULL_TEXT")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="">
                <img
                  src={require("../../../assets/img/ipX.png").default}
                  alt=""
                />
              </div>
            </div>
            <div>
              <div className="uk-text-left">
                <div className="uk-flex uk-flex-column uk-child-1-2@s uk-margin-large-top">
                  <div className="uk-card  uk-border-rounded as-card-padding">
                    <div className="uk-grid-medium uk-grid" data-uk-grid="">
                      <div className="uk-width-auto uk-first-column">
                        <img
                          src={
                            require("../../../assets/img/fileYel.png").default
                          }
                          alt=""
                        />
                      </div>
                      <div className="uk-width-expand">
                        <h5 className="uk-text-bold">
                          {t("PARTNER_TEST_TITLE")}
                        </h5>
                        <p>{t("PARTNER_TEST_TEXT")}</p>
                      </div>
                    </div>
                  </div>

                  <div className="uk-card  uk-border-rounded as-card-padding">
                    <div className="uk-grid-medium uk-grid" data-uk-grid="">
                      <div className="uk-width-auto uk-first-column">
                        <img
                          src={
                            require("../../../assets/img/settingYel.png")
                              .default
                          }
                          alt=""
                        />
                      </div>
                      <div className="uk-width-expand">
                        <h5 className="uk-text-bold">
                          {t("PARTNER_SUPPORT_TITLE")}
                        </h5>
                        <p>{t("PARTNER_SUPPORT_TEXT")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section as-bg-gradient1">
        <div className="uk-container uk-text-center">
          <p className="as-white">{t("PARTNER_INSTALLER")}</p>
          <h3 className="as-white">
            <Trans i18nKey="PARTNER_INSTALLER_TEXT"></Trans>
          </h3>
          <p className="as-white">
            <Trans i18nKey="PARTNER_INSTALLER_VISION"></Trans>
          </p>
          <p className=" uk-margin-large-top">
            <a href="#" className="as-white">
              {" "}
              <span className="as-button-padding as-white uk-box-shadow-small uk-bo">
                {t("PARTNER_GET_STARTED")}
              </span>{" "}
            </a>
          </p>
        </div>
      </section>
    </Fragment>
  );
}
export default Partner;
