import React, { Fragment, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";

function Career() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Fragment>
      <section>
        <div className="uk-grid-small uk-child-width-expand@s" data-uk-grid>
          <div className="uk-padding uk-container uk-margin-xlarge-left  uk-margin-xlarge-top">
            <p className="as-red">
              <Trans i18nKey="CAREER_TITLE" />
            </p>
            <h2 className="uk-text-bold uk-margin-remove-top">
              <Trans i18nKey="CAREER_SUB_TTIEL" />
            </h2>
            <div className="">
              <div className="">
                <small>
                  <Trans i18nKey="CAREER_SUB_TEXT" />
                </small>
              </div>
            </div>
          </div>
          <div className="uk-visible@s">
            <img
              className=" uk-margin-xlarge-top"
              src={require("../../../assets/img/foldArm.png").default}
              alt=""
              data-uk-contain
            />
          </div>
        </div>
      </section>

      <section className="uk-section uk-text-center uk-container">
        <h1 className="uk-h3 as-blue uk-text-bold">
          <Trans i18nKey="CAREER_JOBS" />
        </h1>
        <p className="as-card">
          <Trans i18nKey="CAREER_JOBS_TEXT" />
        </p>
        <div
          className="uk-child-width-1-4@m uk-child-width-1-2@s uk-grid-small uk-grid-match uk-padding uk-padding-remove-vertical"
          data-uk-grid
        >
          <div>
            <div className="uk-card uk-card-body">
              <div className="uk-border-rounded uk-flex uk-flex-middle uk-flex uk-flex-center as-box">
                <img
                  src={require("../../../assets/img/www.png").default}
                  alt=""
                />
              </div>
              <p className="uk-text-bold as-black-1 uk-text-left">
                <Trans i18nKey="CAEERE_TEAM1" />
              </p>
            </div>
          </div>
          <div>
            <div className="uk-card uk-card-body">
              <div className="uk-border-rounded uk-flex uk-flex-middle uk-flex uk-flex-center as-box">
                <img
                  src={require("../../../assets/img/bot.png").default}
                  alt=""
                />
              </div>
              <p className="uk-text-bold as-black-1 uk-text-left">
                <Trans i18nKey="CAEERE_TEAM2" />
              </p>
            </div>
          </div>
          <div>
            <div className="uk-card uk-card-body">
              <div className="uk-border-rounded uk-flex uk-flex-middle uk-flex uk-flex-center as-box">
                <img
                  src={require("../../../assets/img/handShake.png").default}
                  alt=""
                />
              </div>
              <p className="uk-text-bold as-black-1 uk-text-left">
                <Trans i18nKey="CAEERE_TEAM3" />
              </p>
            </div>
          </div>
          <div>
            <div className="uk-card uk-card-body">
              <div className="uk-border-rounded as-bg-red uk-flex uk-flex-middle uk-flex uk-flex-center as-box">
                <img
                  src={require("../../../assets/img/whiteS.png").default}
                  alt=""
                />
              </div>
              <p className="uk-text-bold as-black-1 uk-text-left">
                <Trans i18nKey="CAEERE_TEAM4" />
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section as-bg-grey3 uk-margin-large-bottom as-height-medium uk-flex uk-flex-middle">
        <div className="uk-container">
          <div
            className="uk-text-left uk-flex uk-flex-middle uk-padding uk-padding-remove-vertical"
            data-uk-grid
          >
            <div className="uk-width-1-3@m">
              <div className="uk-card uk-position-relative uk-height-medium uk-card-default as-bg-red uk-card-body uk-border-rounded">
                <h3 className="as-white uk-text-bold">
                  <Trans i18nKey="CAREER_TEXT_DIS" />
                </h3>
                <p className="as-white">
                  <Trans i18nKey="CAREER_TEXT_DIS_TEXT" />
                </p>
              </div>
            </div>
            <div className="uk-width-expand@m">
              <div className="uk-card  uk-card-body">
                <p className="uk-text-bold">
                  <Trans i18nKey="CAREER_TEXT_DIS_TITLE" />
                </p>
                <p>
                  <img
                    src={require("../../../assets/img/line-env.png").default}
                    alt=""
                    width="40px"
                  />{" "}
                  <a href="#">contact@wadpark.com</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}
export default Career;
