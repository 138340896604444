import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { FilterOutline } from "react-ionicons";
import { useTranslation } from "react-i18next";
import { i18n } from "../i18n";

function Header() {
  const location = useLocation();
  const { t } = useTranslation();
  const [language, setLanguage] = useState("fr");

  const handleOnclick = (e: string) => {
    setLanguage(e);
    i18n.changeLanguage(e);
  };
  return (
    <header
      data-uk-sticky={`animation: uk-animation-slide-top; sel-target: .uk-navbar-container; cls-active: as-header-auth as-nav-gray uk-box-shadow-small uk-navbar-sticky; cls-inactive: ${
        location.pathname === "/" ? "as-header" : "as-header-auth"
      } uk-navbar-transparent ; top: 200`}
      className={location.pathname === "/" ? "" : ""}
    >
      <div className={location.pathname === "/" ? "uk-background-cover" : ""}>
        <nav
          className={
            location.pathname === "/"
              ? "uk-navbar-container uk-flex uk-flex-center uk-position-absolute uk-text-center"
              : "uk-navbar-container uk-flex uk-flex-center uk-position-absolute as-navbar uk-box-shadow-small"
          }
          data-uk-navbar
        >
          <div className="uk-navbar-left uk-text-left uk-margin-xlarge-left">
            <ul className="uk-navbar-nav">
              <li className="uk-active ">
                <Link to="/" className="">
                  <img
                    src={require("../assets/img/logo.png").default}
                    className="as-logo-img"
                    alt=""
                  />{" "}
                  <span className="as-logo as-quantity-font">WadPack</span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="uk-navbar-right uk-hidden@m">
            <ul className="uk-navbar-nav " style={{ zIndex: 2000 }}>
              <li className="uk-active ">
                <a data-uk-toggle="target: #offcanvas-nav" href="#">
                  {location.pathname === "/" ? (
                    <FilterOutline
                      color={"#ffffff"}
                      title={""}
                      height="40px"
                      width="40px"
                    />
                  ) : (
                    <FilterOutline
                      color={"#000"}
                      title={""}
                      height="40px"
                      width="40px"
                    />
                  )}
                </a>
              </li>
            </ul>
          </div>
          <div className="uk-navbar-right uk-margin-xlarge-right uk-visible@m">
            <ul className="uk-navbar-nav">
              <li className="uk-active">
                <Link to="/">{t("MENU_HOME")}</Link>
              </li>

              <li>
                <a>
                  {t("MENU_SERVICES")}{" "}
                  <i
                    className="as-margin-small-top"
                    data-uk-icon="icon: chevron-down; ratio: 0.6"
                  ></i>
                </a>
              </li>
              <div data-uk-dropdown="offset: -20" className="wad-dropdown">
                <ul className="uk-nav uk-dropdown-nav uk-dark">
                  <li>
                    <Link to="/particuliers" className="as-nav-gray">
                      {" "}
                      {t("MENU_PAR")}
                    </Link>
                  </li>
                  <li className="uk-nav-divider" style={{ color: "#999" }}></li>
                  <li>
                    <Link to="/entreprises" className="as-nav-gray">
                      {" "}
                      {t("MENU_ENT")}
                    </Link>
                  </li>
                </ul>
              </div>
              <li>
                <Link to="/contraventions"> {t("MENU_CON")}</Link>
              </li>

              <li>
                <Link to="/register" className="as-white">
                  {" "}
                  <span className="as-button-padding as-white uk-box-shadow-small uk-bo">
                    <li>
                      <a className="as-white" href="#">
                        {" "}
                        {t("MENU_REG")}
                      </a>
                    </li>
                  </span>{" "}
                </Link>
              </li>
              <li>
                <Link to="/login"> {t("MENU_IDN")}</Link>
              </li>

              <li>
                <a href="#" className="uk-text-uppercase">
                  {language}
                  <i
                    className="as-margin-small-top"
                    uk-icon="icon: chevron-down; ratio: 0.6"
                  ></i>
                </a>
              </li>
              <div data-uk-dropdown="offset: -20" className="wad-dropdown">
                <ul className="uk-nav uk-dropdown-nav uk-dark uk-padding-remove">
                  <li>
                    <a
                      className="uk-text-muted as-nav-gray"
                      onClick={(e) => handleOnclick("en")}
                      href="#"
                    >
                      EN
                    </a>
                  </li>
                  <li className="uk-nav-divider"></li>
                  <li>
                    <a
                      className="uk-text-muted as-nav-gray"
                      href="#"
                      onClick={(e) => handleOnclick("fr")}
                    >
                      FR
                    </a>
                  </li>
                </ul>
              </div>
            </ul>
          </div>
        </nav>
      </div>

      <div id="offcanvas-nav" data-uk-offcanvas="overlay: true" className="">
        <div className="uk-offcanvas-bar">
          <ul className="uk-nav uk-nav-default">
            <li className="uk-nav-header">WadparkMenu</li>
            <li className="uk-nav-divider"></li>
            <li>
              <a href="">
                <span
                  className="uk-margin-small-right"
                  uk-icon="icon: thumbnails"
                ></span>{" "}
                {t("MENU_HOME")}
              </a>
            </li>
            <li className="uk-nav-divider"></li>

            <li>
              <Link to="/particuliers">
                <span
                  className="uk-margin-small-right"
                  uk-icon="icon: thumbnails"
                ></span>{" "}
                {t("MENU_PAR")}
              </Link>
            </li>
            <li className="uk-nav-divider"></li>
            <li>
              <Link to="/entreprises">
                <span
                  className="uk-margin-small-right"
                  uk-icon="icon: thumbnails"
                ></span>{" "}
                {t("MENU_ENT")}
              </Link>
            </li>

            <li className="uk-nav-divider"></li>
            <li>
              <a href="/contraventions">
                <span
                  className="uk-margin-small-right"
                  uk-icon="icon: thumbnails"
                ></span>{" "}
                {t("MENU_CON")}
              </a>
            </li>
            <li className="uk-nav-divider"></li>
            <li>
              <a href="">
                <span
                  className="uk-margin-small-right"
                  uk-icon="icon: thumbnails"
                ></span>{" "}
                {t("MENU_HOW")}
              </a>
            </li>
            <li className="uk-nav-divider"></li>
            <li>
              <a href="">
                <span
                  className="uk-margin-small-right"
                  uk-icon="icon: thumbnails"
                ></span>{" "}
                {t("MENU_REG")}
              </a>
            </li>
            <li className="uk-nav-divider"></li>
            <li>
              <a href="#">
                <span
                  className="uk-margin-small-right"
                  uk-icon="icon: thumbnails"
                ></span>{" "}
                {t("MENU_IDN")}
              </a>
            </li>
            <li className="uk-nav-divider"></li>

            <li className="uk-nav-divider"></li>
            <li>
              <a href="#">
                <span
                  className="uk-margin-small-right"
                  uk-icon="icon: search"
                ></span>{" "}
                {t("MENU_LANG")}
              </a>
            </li>

            <li></li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;
