import React, { Fragment, useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import axios from "axios";
import "yup-phone";
import { CountryInterface } from "./types";

function Register() {
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [success, setSuccess] = useState<string>("");

  const [countryList, setCountryList] = useState<[]>([]);
  const [companyList, setCompanyList] = useState<[]>([]);
  const [languageList, setLanguageList] = useState<[]>([]);

  useEffect(() => {
    axios({
      method: "get",
      url: "searches/countries/?search=",
    })
      .then((data: any) => {
        if (data) {
          setCountryList(data.data.results);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });

    axios({
      method: "get",
      url: "searches/legalforms/?search=",
    })
      .then((data: any) => {
        if (data) {
          setCompanyList(data.data.results);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });

    axios({
      method: "get",
      url: "searches/languages/",
    })
      .then((data: any) => {
        if (data) {
          setLanguageList(data.data.results);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, [0]);

  const formik = useFormik({
    initialValues: {
      password: "",
      username: "",
      first_name: "",
      last_name: "",
      password2: "",
      address: "",
      company_name: "",
      gender: "M",
      phone: "",
      country: "",
      legal_form: "",
      language: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()

        .min(8, "Minimum 8 characters")
        .required("Required!"),
      username: Yup.string()
        .email()
        .min(1, "Minimum 1 characters")
        .required("Required!"),
      password2: Yup.string()
        .oneOf([Yup.ref("password")], "Password does not match")
        .required("Required!"),
      address: Yup.string()
        .min(1, "Minimum 1 characters")
        .required("Required!"),
      company_name: Yup.string()
        .min(1, "Minimum 1 characters")
        .required("Required!"),

      gender: Yup.string().required("Required!"),

      phone: Yup.string().phone().required("Required!"),
      country: Yup.string()
        .min(1, "Minimum 1 characters")
        .required("Required!"),
    }),
    onSubmit: (values) => {
      console.log(values);
      setLoading(true);
      axios({
        method: "post",
        url: "accounts/signup/partner/",
        data: values,
      })
        .then((data: any) => {
          setMessage("");
          setSuccess("");
          console.log("entered");
          console.log(data);
          setLoading(false);
          setSuccess(data.response.data.detail);
        })
        .catch((err: any) => {
          setMessage("");
          setSuccess("");
          setMessage(err.response.data.detail);

          setLoading(false);
        });
    },
  });

  return (
    <Fragment>
      <section className="as-bg-gradient ">
        <div className="uk-margin-large-top ">
          <div
            className="uk-text-center uk-grid-match uk-grid-collapse uk-flex uk-flex-center as-container uk-padding "
            data-uk-grid
          >
            <div className="uk-width-1-4@m uk-text-left uk-visible@s">
              <div
                className="uk-card uk-card-default uk-text-left uk-background-cover as-auth as-bg-img-1"
                style={{
                  backgroundImage: `url(${
                    require("../../../assets/img/imgAuth.png").default
                  })`,
                }}
              >
                <h2 className="uk-h3 uk-text-bold uk-text-center uk-padding">
                  Do your Parking with WadPark{" "}
                </h2>
              </div>
            </div>

            <div className="uk-width-1-2@m uk-text-left">
              <div className="uk-card uk-card-default uk-card-body as-card-sign as-bg-img-1">
                <h4 className="uk-h4">Sign up</h4>
                <form
                  onSubmit={formik.handleSubmit}
                  className="uk-grid-small"
                  data-uk-grid
                >
                  <div className="uk-width-1-1">
                    <input
                      className="uk-input"
                      type="email"
                      placeholder="Email"
                      value={formik.values.username}
                      onChange={formik.handleChange}
                      name="username"
                    />
                    {formik.errors.username && formik.touched.username && (
                      <p className="uk-text-danger">{formik.errors.username}</p>
                    )}
                  </div>
                  <div className="uk-width-1-1">
                    <input
                      className="uk-input"
                      type="text"
                      placeholder="First name"
                      name="first_name"
                      value={formik.values.first_name}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.first_name && formik.touched.first_name && (
                      <p className="uk-text-danger">
                        {formik.errors.first_name}
                      </p>
                    )}
                  </div>
                  <div className="uk-width-1-1">
                    <input
                      className="uk-input"
                      type="text"
                      placeholder="Last name"
                      name="last_name"
                      value={formik.values.last_name}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.last_name && formik.touched.last_name && (
                      <p className="uk-text-danger">
                        {formik.errors.last_name}
                      </p>
                    )}
                  </div>
                  <div className="uk-width-1-1">
                    <input
                      className="uk-input"
                      type="text"
                      placeholder="Password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.password && formik.touched.password && (
                      <p className="uk-text-danger">{formik.errors.password}</p>
                    )}
                  </div>
                  <div className="uk-width-1-1">
                    <input
                      className="uk-input"
                      type="text"
                      placeholder="Confirm Password"
                      name="password2"
                      value={formik.values.password2}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.password2 && formik.touched.password2 && (
                      <p className="uk-text-danger">
                        {formik.errors.password2}
                      </p>
                    )}
                  </div>
                  <div className="uk-width-1-1">
                    <input
                      className="uk-input"
                      type="text"
                      placeholder="Phone number"
                      name="phone"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.phone && formik.touched.phone && (
                      <p className="uk-text-danger">{formik.errors.phone}</p>
                    )}
                  </div>
                  <div className="uk-width-1-1">
                    <input
                      className="uk-input"
                      type="text"
                      placeholder="Address"
                      name="address"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.address && formik.touched.address && (
                      <p className="uk-text-danger">{formik.errors.address}</p>
                    )}
                  </div>
                  <div className="uk-width-1-1">
                    <input
                      className="uk-input"
                      type="text"
                      placeholder="Company"
                      name="company_name"
                      value={formik.values.company_name}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.company_name &&
                      formik.touched.company_name && (
                        <p className="uk-text-danger">
                          {formik.errors.company_name}
                        </p>
                      )}
                  </div>

                  <div className="uk-width-1-1">
                    <select
                      className="uk-select"
                      id="form-horizontal-select"
                      placeholder="City"
                      name="country"
                      value={formik.values.country}
                      onChange={formik.handleChange}
                    >
                      <option value="">Select a country</option>
                      {countryList.map((item: CountryInterface) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {formik.errors.country && formik.touched.country && (
                      <p className="uk-text-danger">{formik.errors.country}</p>
                    )}
                  </div>

                  <div className="uk-width-1-1">
                    <select
                      className="uk-select"
                      id="form-horizontal-select"
                      placeholder="Legal Form"
                      name="legal_form"
                      value={formik.values.legal_form}
                      onChange={formik.handleChange}
                    >
                      <option value="">Select a Legal form</option>
                      {companyList.map((item: CountryInterface) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {formik.errors.legal_form && formik.touched.legal_form && (
                      <p className="uk-text-danger">
                        {formik.errors.legal_form}
                      </p>
                    )}
                  </div>
                  <div className="uk-width-1-1">
                    <select
                      className="uk-select"
                      id="form-horizontal-select"
                      placeholder="Language"
                      name="language"
                      value={formik.values.language}
                      onChange={formik.handleChange}
                    >
                      {" "}
                      <option value="">Select a Language</option>
                      {languageList.map((item: CountryInterface) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {formik.errors.language && formik.touched.language && (
                      <p className="uk-text-danger">{formik.errors.language}</p>
                    )}
                  </div>
                  <div className="uk-width-1-1">
                    <select
                      className="uk-select"
                      id="form-horizontal-select"
                      placeholder="Gender"
                      name="gender"
                      value={formik.values.gender}
                      onChange={formik.handleChange}
                    >
                      <option value="Male">M</option>
                      <option value="Female">F</option>
                    </select>
                    {formik.errors.gender && formik.touched.gender && (
                      <p className="uk-text-danger">{formik.errors.gender}</p>
                    )}
                  </div>

                  <div className="uk-width-1-1">
                    <button
                      type="submit"
                      className="uk-button uk-button-default as-bg-red as-white uk-border-rounded uk-width-1-1  uk-text-capitalize"
                    >
                      join wadPack
                    </button>
                  </div>
                  <br />

                  <div className="uk-flex uk-flex-bottom  uk-padding-small">
                    <p className="">
                      Aready Parking with us ?
                      <Link to="/login" className="as-red">
                        {" "}
                        Sign in
                      </Link>
                    </p>
                  </div>
                  {loading && (
                    <div className="uk-width-1-1 uk-flex  uk-flex-center">
                      <span
                        className="uk-margin-small-right"
                        uk-spinner="ratio: 2"
                      ></span>
                    </div>
                  )}
                  <div className="uk-width-1-1">
                    {message && <p className="uk-text-danger">{message}</p>}
                  </div>
                  <div className="uk-width-1-1">
                    {success && <p className="uk-text-success">{success}</p>}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}
export default Register;
