import React, { Fragment, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";

function Station() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Fragment>
      <section>
        <div className="uk-grid-small uk-child-width-expand@s" data-uk-grid>
          <div className="uk-flex uk-flex-middle">
            <div className="uk-padding uk-container uk-margin-xlarge-left">
              <h2 className="uk-margin-remove-top">
                <Trans i18nKey="STATION_TITLE" />
              </h2>
              <div className="">
                <div className="">
                  <small>
                    <Trans i18nKey="STATION_TITLE_TEXT" />
                  </small>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div
              className="uk-background-contain uk-height-large uk-panel uk-flex uk-flex-middle"
              style={{
                backgroundImage: `url(${
                  require("../../../assets/img/qCircle.png").default
                })`,
                backgroundPosition: "100% 100%",
              }}
            >
              <div
                className="uk-flex uk-flex-middle"
                style={{
                  width: "400px",
                  height: "300px",
                  backgroundColor: "#10101",
                  position: "relative",
                  borderRadius: "10px",
                }}
              >
                <video
                  controls
                  style={{
                    position: "relative",
                    height: "100%",
                    borderRadius: "10px",
                  }}
                >
                  <source
                    className=""
                    src="https://yootheme.com/site/images/media/yootheme-pro.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="uk-section uk-background-cover uk-panel"
        style={{
          backgroundImage: `url(${
            require("../../../assets/img/curvePi.png").default
          })`,
        }}
      >
        <div className="uk-container">
          <div className="uk-margin-medium-bottom">
            <h3 className="uk-text-uppercase uk-text-center">
              {" "}
              <Trans i18nKey="STATION_CENTERS_TITLE" />
            </h3>
            <p className="as-red uk-text-center">
              <Trans i18nKey="STATION_CENTERS_TEXT1" />
            </p>
            <p className="uk-text-center">
              <Trans i18nKey="STATIOM_CENTER_TEXT2" />
            </p>
          </div>

          <div
            className="uk-child-width-1-3@m uk-grid-small uk-grid-match"
            data-uk-grid
          >
            <div>
              <div className="uk-card uk-card-default uk-card-body as-bg-red uk-border-rounded as-white">
                <h3 className="uk-card-title as-white uk-text-bold">
                  <Trans i18nKey="STATION_PARKING_TITLE" />
                </h3>
                <p>
                  <Trans i18nKey="STATION_PARKING_TEXT" />
                </p>
                <p>
                  <Trans i18nKey="STATION_PARKING_TEXT1" />
                </p>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-body uk-padding-remove-vertical">
                <h4 className="uk-margin-remove-top">
                  <Trans i18nKey="STATION_MOBILITY" />
                </h4>
                <p className="uk-margin-remove-top">
                  <Trans i18nKey="STATION_MOBILITY_TEXT" />
                </p>
                <h4 className="uk-margin-remove-top">
                  <Trans i18nKey="STATION_SEC" />
                </h4>
                <p className="uk-margin-remove-top">
                  <Trans i18nKey="STATION_SEC_TEXT" />
                </p>
                <h4 className="uk-margin-remove-top">
                  <Trans i18nKey="STATION_PRICING" />
                </h4>
                <p className="uk-margin-remove-top">
                  <Trans i18nKey="STATION_PRICING_TEXT" />
                </p>
                <h4 className="uk-margin-remove-top">
                  <Trans i18nKey="STATION_VEHICLE" />
                </h4>
                <p className="uk-margin-remove-top">
                  <Trans i18nKey="STATION_VEHICLE_TEXT" />
                </p>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-body uk-padding-remove-vertical">
                <p>
                  <Trans i18nKey="STATION_DIGI" />
                </p>
                <div>
                  <h4 style={{ color: "red" }}>
                    <Trans i18nKey="STATION_DIGI_DISC" />
                  </h4>
                  <ul className="uk-list uk-list-disc">
                    <Trans i18nKey="STATION_DIGI_LIST" />
                  </ul>
                  <p>
                    <Trans i18nKey="STATION_TEXT_DIGI" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </section>

      <section className="uk-section uk-container">
        <div>
          <div
            className="uk-child-width-1-2@m uk-child-width-1-1@s uk-grid-small uk-grid-match"
            data-uk-grid
          >
            <div>
              <div className="uk-card uk-card-body">
                <small className="as-red">
                  {" "}
                  <Trans i18nKey="STATION_AIR" />
                </small>
                <h2 className="uk-text-bold">
                  <Trans i18nKey="STATION_AIR_TITLE" />
                </h2>
                <p>
                  <Trans i18nKey="STATION_AIR_TEXT" />
                </p>
                <div>
                  <ul className="uk-list uk-list-disc">
                    <Trans i18nKey="STATION_AIR_LIST" />
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-body">
                <div className="uk-visible@m">
                  <div className="uk-flex uk-flex-center@s uk-flex-right@m">
                    <img
                      className="as-grey-shadow"
                      src={require("../../../assets/img/driver6.png").default}
                      alt=""
                      width="400px"
                      height="600px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section uk-container">
        <div>
          <div
            className="uk-child-width-1-2@m uk-child-width-1-1@s uk-grid-small uk-grid-match"
            data-uk-grid
          >
            <div>
              <div className="uk-card uk-card-body">
                <div className="uk-visible@m">
                  <div className="uk-flex uk-flex-center@s uk-flex-right@m">
                    <img
                      className="as-grey-shadow-left"
                      src={require("../../../assets/img/driver5.png").default}
                      alt=""
                      width="400px"
                      height="600px"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-body">
                <small className="as-red">
                  <Trans i18nKey="STATION_HOSPITALS" />
                </small>
                <h2 className="uk-text-bold">
                  <Trans i18nKey="STATION_HOSPITALS" />
                </h2>
                <p>
                  <Trans i18nKey="STATION_HOSPITALS_TEXT" />
                </p>
                <div>
                  <h5 className="uk-text-bold">
                    {" "}
                    <Trans i18nKey="STATION_CHARA" />
                  </h5>
                  <ul className="uk-list uk-list-disc">
                    <Trans i18nKey="STATION_CHARA_LIST" />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section uk-container">
        <div>
          <div
            className="uk-child-width-1-2@m uk-child-width-1-1@s uk-grid-small uk-grid-match"
            data-uk-grid
          >
            <div>
              <div className="uk-card uk-card-body">
                <small className="as-red">
                  {" "}
                  <Trans i18nKey="STATION_HOTEL" />
                </small>
                <h2 className="uk-text-bold">
                  {" "}
                  <Trans i18nKey="STATION_HOTEL_TITLE" />
                </h2>
                <p>
                  <Trans i18nKey="STATION_HOTEL_TEXT1" />
                </p>
                <p>
                  <Trans i18nKey="STATION_HOTEL_TEXT2" />
                </p>
                <p>
                  <Trans i18nKey="STATION_HOTEL_TEXT3" />
                </p>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-body">
                <div className="uk-visible@m">
                  <div className="uk-flex uk-flex-center@s uk-flex-right@m">
                    <img
                      className="as-grey-shadow"
                      src={require("../../../assets/img/driver4.png").default}
                      alt=""
                      width="400px"
                      height="600px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section uk-container">
        <div>
          <div
            className="uk-child-width-1-2@m uk-child-width-1-1@s uk-grid-small uk-grid-match"
            data-uk-grid
          >
            <div>
              <div className="uk-card uk-card-body">
                <div className="uk-visible@m">
                  <div className="uk-flex uk-flex-center@s uk-flex-right@m">
                    <img
                      className="as-grey-shadow-left"
                      src={require("../../../assets/img/driver3.png").default}
                      alt=""
                      width="400px"
                      height="600px"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-body">
                <small className="as-red">
                  <Trans i18nKey="STATION_SPORT" />
                </small>
                <h2 className="uk-text-bold">
                  <Trans i18nKey="STATION_SUB_TEXT" />
                </h2>
                <ul className="uk-list uk-list-disc">
                  <Trans i18nKey="STATION_SPORT_LIST" />
                </ul>
                <div>
                  <h5 className="uk-text-bold">
                    <Trans i18nKey="STATION_SPORT_CHARA" />
                  </h5>
                  <ul className="uk-list uk-list-disc">
                    <Trans i18nKey="STATION_SPORT_CHARA_LIST" />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section uk-container">
        <div>
          <div
            className="uk-child-width-1-2@m uk-child-width-1-1@s uk-grid-small uk-grid-match"
            data-uk-grid
          >
            <div>
              <div className="uk-card uk-card-body">
                <small className="as-red">
                  <Trans i18nKey="STATION_ADMIN" />
                </small>
                <h2 className="uk-text-bold">
                  <Trans i18nKey="STATION_ADMIN_PROF" />
                </h2>
                <p>
                  <Trans i18nKey="STATION_ADMIN_PROF_TEXT" />
                </p>

                <div>
                  <h4 className="uk-text-bold">
                    {" "}
                    <Trans i18nKey="STATION_ADMIN_BEN" />
                  </h4>
                  <ul className="uk-list uk-list-disc">
                    <Trans i18nKey="STATION_ADMIN_BEN_LIST" />
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-body">
                <div className="uk-visible@m">
                  <div className="uk-flex uk-flex-center@s uk-flex-right@m">
                    <img
                      className="as-grey-shadow"
                      src={require("../../../assets/img/driver2.png").default}
                      alt=""
                      width="400px"
                      height="600px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}
export default Station;
